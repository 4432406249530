import React from 'react';
import PropTypes from 'prop-types';

import {useCaptchaCode} from 'contexts/CaptchaContext';

import HCaptcha from './HCaptcha';
import ReCaptcha from './ReCaptcha';

IPCaptcha.propTypes = {
  onVerify: PropTypes.func.isRequired,
  onExpire: PropTypes.func,
};

function IPCaptcha(props) {
  const {onVerify, onExpire} = props;
  const {captchaCode} = useCaptchaCode();

  function handleLoad() {
    // nothing yet
  }

  return (
    <>
      {captchaCode === 'h' ? (
        <HCaptcha
          onVerify={(response) => onVerify(response, 'hcaptcha')}
          onExpire={onExpire}
          onLoad={handleLoad}
        />
      ) : (
        <ReCaptcha
          onVerify={(response) => onVerify(response, 'recaptcha')}
          onExpire={onExpire}
          onLoad={handleLoad}
        />
      )}
    </>
  );
}

export default IPCaptcha;
