import React from 'react';
import PropTypes from 'prop-types';
import {keys} from 'lodash/object';
import {Link} from 'react-router-dom';

import {EXTERNAL_URL} from 'configs';
import {Button} from 'reactstrap';
import {IconFA} from '../Icons';

const {DATA_TEMPLATE} = EXTERNAL_URL;

const propTypes = {
  type: PropTypes.oneOf([...keys(DATA_TEMPLATE)]).isRequired,
};

const defaultProps = {
  icon: false,
  color: 'primary',
  outline: false,
};

function ButtonDataTemplate(props) {
  const {icon, type, children, ...rest} = props;

  return (
    <Button
      tag={Link}
      to={DATA_TEMPLATE[type]}
      target="_blank"
      rel="noopener noreferrer"
      {...rest}
    >
      <IconFA name="file" className="fa-lg mr-1" />
      {children}
    </Button>
  );
}

ButtonDataTemplate.propTypes = propTypes;
ButtonDataTemplate.defaultProps = defaultProps;

export default ButtonDataTemplate;
