import http, {makeSearchParams} from 'utils/http';

export const getCompanyListApi = (clId = 0) => {
  return http('GET', `company/company-list/${clId}/api-keys`);
};

export const postCompanyListApi = (clId = 0, formData) => {
  return http('POST', `company/company-list/${clId}/api-keys`, formData);
};

export const deleteCompanyApiUrl = (clId = 0, apiKeyId) => {
  return http('DELETE', `company/company-list/${clId}/api-keys/${apiKeyId}`);
};

export const getCompanyApiUrl = () => {
  return http('GET', 'company/bm-api-url');
};

// COMPANIES LIST
export const getCompanyList = (queries, clId = 0) => {
  return http('GET', `company-list/${clId}/items`, makeSearchParams(queries));
};

export const getCompanyListStats = (clId = 0) => {
  return http('GET', `company-list/${clId}/stats`);
};

export const getCompanyListUpdate = (clId = 0) => {
  return http('GET', `company-list/${clId}`);
};

// GLOBAL COMPANIES LIST
export const getGlobalCompanyList = (queries) => {
  return http('GET', `/global/company-list/items`, makeSearchParams(queries));
};

export const getGlobalCompanyListStats = (clId = 0) => {
  return http('GET', `/global/company-list/stats`);
};

export const getGlobalCompanyListUpdate = () => {
  return http('GET', `/global/company-list`);
};

// COMPANY DETAIL PAGE
export const getCompanyListItemById = (clId = 0, itemId) => {
  return http('GET', `company-list/${clId}/items/${itemId}`);
};

// COMPANY CERTS LIST
export const getCompanyListItemCertsStats = (clId = 0, companyId) => {
  return http(
    'GET',
    `company-list/${clId}/items/${companyId}/certifications-stats`
  );
};

export const getCompanyListItemCerts = (clId = 0, companyId, queries) => {
  return http(
    'GET',
    `company-list/${clId}/items/${companyId}/certifications`,
    makeSearchParams(queries)
  );
};

export const getCompanyListItemCertsStandards = (clId = 0, id, queries) => {
  return http(
    'GET',
    `company-list/${clId}/items/${id}/certifications-standards`,
    makeSearchParams(queries)
  );
};

export const getCompanyCerts = (clId = 0, queries) => {
  return http(
    'GET',
    `/company-list/${clId}/items/certifications`,
    makeSearchParams(queries)
  );
};

export const getCompanyCertStats = (clId = 0) => {
  return http('GET', `/company-list/${clId}/items/certifications-stats`);
};

export const getCertsLocalView = (clId = 0, cliId, mdbcertId) => {
  return http(
    'GET',
    `/company-list/${clId}/items/${cliId}/certifications/${mdbcertId}`
  );
};

export const getCertsGlobalView = (clId = 0, mdbcertId) => {
  return http('GET', `/company-list/${clId}/items/certifications/${mdbcertId}`);
};

export const getCompanyCertStandards = (queries, clId = 0) => {
  return http(
    'GET',
    `/company-list/${clId}/items/certifications-standards`,
    makeSearchParams(queries)
  );
};

export const deleteCompanyCerts = (clId = 0, mdbcertId) => {
  return http(
    'DELETE',
    `/company-list/${clId}/items/certifications/${mdbcertId}`
  );
};

// verifying of certs
export const getConfirmTransaction = (clId = 0, payload) => {
  return http('GET', `/company-list/${clId}/confirm-transaction`, payload);
};

// verifying certs by id
export const getConfirmTransactionById = (clId = 0, cliId) => {
  return http(
    'GET',
    `/company-list/${clId}/items/${cliId}/confirm-transaction`
  );
};

// refreshing of certs
export const getCertConfirmTransaction = (clId = 0, payload) => {
  return http(
    'GET',
    `/company-list/${clId}/items/certifications/confirm-transaction`,
    payload
  );
};

// refreshing of certs by id
export const getCertConfirmTransactionById = (clId = 0, cliId, payload) => {
  return http(
    'GET',
    `/company-list/${clId}/items/${cliId}/certifications/confirm-transaction`,
    payload
  );
};

/**
 * GET company group list
 * @returns
 */
export const getCompanyListGroup = (queries) => {
  return http('GET', '/company-list', makeSearchParams(queries));
};

/**
 * GET last verified at
 * @returns data
 */
export const getCompanyGlobalLastVerified = () => {
  return http('GET', '/global/company-list/last-verified');
};

/**
 * GET last verified at by CLID
 * @param {*} clId
 * @returns
 */
export const getCompanyGlobalLastVerifiedById = (clId) => {
  return http('GET', `/company-list/${clId}/last-verified`);
};

/**
 * Create company group
 * @param {*} payload
 * @returns
 */
export const postCompanyListGroup = (payload) => {
  return http('POST', `/company-list`, payload);
};

/**
 * Update company group
 * @param {*} payload
 * @returns
 */
export const putCompanyListGroup = (payload, clId) => {
  return http('PUT', `/company-list/${clId}`, payload);
};

/**
 * DELETE company group
 * @param {*} payload
 * @param {*} clId
 * @returns
 */
export const deleteCompanytListGroup = (clId) => {
  return http('DELETE', `/company-list/${clId}`);
};

export const getCompanyListGroupById = (clId) => {
  return http('GET', `/company-list/${clId}`);
};

// company list tags
export const putGlobalCompanyListItemsById = (id, data) => {
  return http('PUT', `/global/company-list/items/${id}`, data);
};

// company list item certs & global certs tags
export const putGlobalCompanyListItemsCliIdCertsMdbId = (
  cliId,
  mdbId,
  data
) => {
  return http(
    'PUT',
    `global/company-list/items/${cliId}/certifications/${mdbId}`,
    data
  );
};

// Company List By Import Activity
export const getCompanyListItemsByImport = (id, queries) => {
  return http(
    'GET',
    `/company-list/items/import/${id}`,
    makeSearchParams(queries)
  );
};

export const getCompanyListStatsById = (id, queries) => {
  return http(
    'GET',
    `/company-list/items/import/${id}/stats`,
    makeSearchParams(queries)
  );
};

export const getCompanyCertStatsById = (id, queries) => {
  return http(
    'GET',
    `/company-list/items/import/${id}/certifications-stats`,
    makeSearchParams(queries)
  );
};

// company list item certs & global certs - add/bulk add to watchlist
export const postCompanyListItemsAddToWatchlistWlId = (wlId = 0, data) => {
  return http('POST', `/company-list/items/add-to-watchlist/${wlId}`, data);
};

export const getCompanyCountriesCertifications = (queries) => {
  return http(
    'GET',
    '/global/company-list/items/countries-certifications',
    makeSearchParams(queries)
  );
};

/**
 * DELETE Company List Item
 * @param {*} clId
 * @returns
 */
export const deleteCompanytListItem = (payload) => {
  return http('DELETE', `/global/company-list/items`, payload);
};

// new
export function getVerificationStats() {
  return http('GET', 'global/verification-stats');
}

export function getGlobalListingCount() {
  return http('GET', 'global/listing-count');
}

export function getImportLastLookup(id) {
  return http('GET', `/company-list/items/import/${id}/last-update`);
}

export function getCertStandardsLocal(clId = 0, cliId, queries) {
  return http(
    'GET',
    `/company-list/${clId}/items/${cliId}/certifications-standards`,
    makeSearchParams(queries)
  );
}
