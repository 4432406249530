import React from 'react';
import PropTypes from 'prop-types';
import {Button} from 'reactstrap';

const propTypes = {
  isEdit: PropTypes.bool.isRequired,
  setIsEdit: PropTypes.func.isRequired,
};

function ButtonEdit({isEdit, setIsEdit, ...rest}) {
  return (
    <Button
      color={isEdit ? 'secondary' : 'dark'}
      onClick={() => setIsEdit(!isEdit)}
      {...rest}
    >
      {isEdit ? 'Cancel' : 'Edit'}
    </Button>
  );
}

ButtonEdit.propTypes = propTypes;

export default ButtonEdit;
