import React from 'react';
import PropTypes from 'prop-types';
import {EXTERNAL_URL} from 'configs';

const {DOC_GUIDE} = EXTERNAL_URL;

const propTypes = {
  document: PropTypes.oneOf([
    'accounts',
    'cbs',
    'certs',
    'ces',
    'standards',
    'users',
    'settings',
  ]).isRequired,
};

function ButtonDocGuide({document, text}) {
  return (
    <a
      className="ml-2"
      href={DOC_GUIDE[document]}
      target="_blank"
      rel="noopener noreferrer"
    >
      {text ? text : 'More Detail'}
    </a>
  );
}

ButtonDocGuide.propTypes = propTypes;

export default ButtonDocGuide;
