import React from 'react';
import PropTypes from 'prop-types';
import {EXTERNAL_URL} from 'configs';

const {DEV_GUIDE} = EXTERNAL_URL;

const propTypes = {
  document: PropTypes.oneOf([
    'file_upload',
    'ftp_access',
    'api_access',
    'data_mapping',
  ]).isRequired,
};

function ButtonDevGuide({document, text}) {
  switch (document) {
    default:
      text = 'View Instructions';
      break;
    case 'file_upload':
      if (!text) text = 'View File Upload Instruction';
      break;
    case 'ftp_access':
      if (!text) text = 'View FTP Access Instructions';
      break;
    case 'api_access':
      if (!text) text = 'View API Access Instructions';
      break;
    case 'data_mapping':
      if (!text) text = 'View Data Mapping Instructions';
      break;
  }

  return (
    <a
      className="ml-2"
      href={DEV_GUIDE[document]}
      target="_blank"
      rel="noopener noreferrer"
    >
      {text}
    </a>
  );
}

ButtonDevGuide.propTypes = propTypes;

export default ButtonDevGuide;
