import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Modal, ModalBody} from 'reactstrap';
import {Button} from 'reactstrap';
import {IconFA} from '../Icons';

const propTypes = {
  type: PropTypes.oneOf(['link', 'button']),
  video: PropTypes.string.isRequired,
};

const defaultProps = {
  color: 'primary',
  children: 'Video Guide',
};

function ButtonVideoGuide(props) {
  const [isOpen, setIsOpen] = useState(false);
  const {type, icon, video, children, ...rest} = props;

  function handleClick(e) {
    e.preventDefault();
    setIsOpen(true);
  }

  const src = video ? `https://www.youtube-nocookie.com/embed/${video}` : '';

  return (
    <React.Fragment>
      {type === 'link' ? (
        <Link to="#" onClick={handleClick} {...rest}>
          <IconFA name="youtube-play" className="fa-lg mr-1" />
          {children}
        </Link>
      ) : (
        <Button to="#" onClick={handleClick} outline {...rest}>
          <IconFA name="youtube-play" className="fa-lg mr-1" />
          {children}
        </Button>
      )}

      <Modal size="xl" isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
        <ModalBody>
          <div className="embed-responsive embed-responsive-16by9">
            <iframe
              src={src}
              title="IAF CertSearch Video Guide"
              className="embed-responsive-item"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}

ButtonVideoGuide.propTypes = propTypes;
ButtonVideoGuide.defaultProps = defaultProps;

export default ButtonVideoGuide;
