import React, {createRef} from 'react';
import PropTypes from 'prop-types';
import ReactHCaptcha from '@hcaptcha/react-hcaptcha';
import {HCAPTCHA_SITEKEY} from 'configs';

HCaptcha.propTypes = {
  onVerify: PropTypes.func.isRequired,
  onExpire: PropTypes.func,
};

function HCaptcha(props) {
  const {onVerify, onExpire, onLoad} = props;
  const captchaRef = createRef();

  function handleLoad() {
    // this reaches out to the hCaptcha JS API and runs the
    // execute function on it. you can use other functions as
    // documented here:
    // https://docs.hcaptcha.com/configuration#jsapi
    //
    // @note: Hide trigger
    // captchaRef.current.execute();

    onLoad(false); // isLoading false
  }

  function handleVerify(token) {
    onVerify(token);
  }

  function handleError() {
    alert('Error has been occurred. Please try again.');
    //captchaRef.current.resetCaptcha();
  }

  function handleExpire() {
    alert('Captcha has expired. Please try again.');
    captchaRef.current.resetCaptcha();
    onExpire && onExpire();
  }

  return (
    <ReactHCaptcha
      ref={captchaRef}
      sitekey={HCAPTCHA_SITEKEY}
      // onLoad={handleLoad}
      onVerify={handleVerify}
      onExpire={handleExpire}
      onError={handleError}
      render={handleLoad}
    />
  );
}

export default HCaptcha;
