import http, {makeSearchParams} from 'utils/http';

export const getAccount = () => {
  return http('GET', `account`);
};

export const updateAccount = (data) => {
  return http('PUT', `account`, data);
};

export const updateAccountConsent = (data) => {
  return http('PUT', `account/consent`, data);
};

export const changePassword = (data) => {
  return http('PUT', `account/password`, data);
};

export const getAccountCompany = () => {
  return http('GET', `settings-company`);
};

export const updateAccountCompany = (data) => {
  return http('PUT', `settings-company`, data);
};

export const sendConfirmEmail = () => {
  return http('POST', `account/resend-confirm-email`);
};

export const getAccountActivities = (queries) => {
  return http('GET', 'account/activity', makeSearchParams(queries));
};

export const signOutActivities = (token) => {
  return http('POST', `account/activity/sign_out?token=${token}`);
};

/**
 * Get notification items
 * @param queries: nextKey
 *
 */
export const getAccountNotifications = (queries) => {
  return http('GET', `account/notifications`, queries);
};

export const getAccountNotificationCount = () => {
  return http('GET', `account/notifications/count`);
};

export const updateAccountNotification = (data) => {
  return http('PUT', `account/notifications`, data);
};

export const getAccountOTP = () => {
  return http('POST', 'account/otp/subscribe');
};

export const subscribeAccountOTP = (data) => {
  return http('POST', 'account/otp/verify', data);
};

export const unsubscribeAccountOTP = (data) => {
  return http('DELETE', 'account/otp/unsubscribe', data);
};

// Watchlist Api
export const getAccountWatchList = (data) => {
  return http('GET', `account/watchlist`, makeSearchParams(data));
};

export const getAccountWatchListByCert = (certId, data) => {
  return http(
    'GET',
    `account/watchlist/${certId}/cert`,
    makeSearchParams(data)
  );
};

export const addAccountWatchList = (mdbCertId) => {
  return http('POST', `account/watchlist`, {mdbcert_id: mdbCertId});
};

export const deleteAccountWatchListById = (certId) => {
  return http('DELETE', `account/watchlist/${certId}`);
};

export const deleteAccountWatchList = (data) => {
  return http('DELETE', `account/watchlist`, data);
};

export const updateAccountEmailGuard = (data) => {
  return http('PUT', `account/email-guard`, data);
};

export const accountSendEmailGuard = (data) => {
  return http('POST', 'account/send-email-guard', data);
};

export const accountVerifyEmailGuard = (data) => {
  return http('POST', 'account/verify-email-guard', data);
};

// Search History API

export const getAccountHistory = (data) => {
  return http('GET', `account/history`, data);
};

export const getAccountByIdCert = (id) => {
  return http('GET', `account/history/${id}/cert`);
};

export const deleteAccountHistoryById = (id, data) => {
  return http('DELETE', `account/history/${id}`, data);
};

// Multiple Delete History
export const deleteAccountHistory = (data) => {
  return http('DELETE', `account/history`, data);
};

export const getAccountCheckPassword = (data) => {
  return http('POST', '/account/password/check', data);
};

export const postAccountSendAlternateEmail = (data) => {
  return http('POST', '/account/send-alternate-email', data);
};

export const putAccountAlternateEmail = (data) => {
  return http('PUT', '/account/alternate-email', data);
};

export const putAccountAlternateEmailStatus = (data) => {
  return http('PUT', '/account/alternate-email-status', data);
};

export const postAccountOTPSubscribe = (data) => {
  return http('POST', '/account/otp/subscribe', data);
};

export const postAccountOTPVerify = (data) => {
  return http('POST', '/account/otp/verify', data);
};

export const deleteAccountOTPUnsubscribe = (data) => {
  return http('DELETE', '/account/otp/unsubscribe', data);
};
