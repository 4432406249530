import React from 'react';
import classNames from 'classnames';
import {Badge as ReactBadge} from 'reactstrap';
import styles from './styles.module.scss';

function Badge(props) {
  const {className, color, outline, ...rest} = props;

  if (outline) {
    return (
      <ReactBadge
        {...rest}
        className={classNames(styles['react-badge'], className, {
          [styles['outline-primary']]: color === 'outline-primary',
          [styles['outline-secondary']]: color === 'outline-secondary',
          [styles['outline-success']]: color === 'outline-success',
          [styles['outline-warning']]: color === 'outline-warning',
          [styles['outline-danger']]: color === 'outline-danger',
          [styles['outline-info']]: color === 'outline-info',
        })}
      />
    );
  }

  return (
    <ReactBadge
      color={color}
      className={classNames(
        styles['react-badge'],
        className,
        `border border-${color}`
      )}
      {...rest}
    />
  );
}

export default Badge;
