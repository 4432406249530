import 'rmc-steps/assets/index.css';
import 'styles/main.scss';

import React, {useEffect} from 'react';
import {Router, useLocation} from 'react-router-dom';
import {createBrowserHistory} from 'history';
import {ErrorBoundary} from 'react-error-boundary';

import routes from 'routes';
import {ROUTER_SCROLL_TYPES} from 'configs';
import {ERROR_STATUS} from 'configs';
import renderRoutes from 'utils/render-routes';
import {setAxiosConfig} from 'utils/http';
import {setDayJs} from 'utils/dates';

import Errors from 'views/errors/Errors';
import {Page} from 'components/Pages';
import GoogleTranslateElement from 'components/GoogleTranslateElement';
import {PageProvider} from 'contexts/PageContext';
import AppContextProvider from './AppContextProvider';

const browserHistory = createBrowserHistory();

function ErrorFallback({error, resetErrorBoundary}) {
  let errCode = ERROR_STATUS['404'];
  if (
    error.message.includes('removeChild') ||
    error.message.includes('insertBefore')
  ) {
    errCode = ERROR_STATUS['google_translate'];
  }
  return (
    <Router history={createBrowserHistory({forceRefresh: true})}>
      <PageProvider>
        <Page>
          <Errors errCode={errCode} onTryAgain={resetErrorBoundary} />
        </Page>
      </PageProvider>
    </Router>
  );
}

setAxiosConfig();
setDayJs();

function ScrollToTop() {
  const {pathname, search, hash, state} = useLocation();

  useEffect(() => {
    setTimeout(() => {
      if (
        state &&
        !!state.scroll &&
        state.scroll === ROUTER_SCROLL_TYPES.scroll
      ) {
        const id = hash.replace('#', '');
        const element =
          document.getElementById(hash) || document.getElementById(id);

        if (element) {
          element.scrollIntoView({behavior: 'smooth', block: 'center'});
        }
        return;
      }

      if (!state || state.scroll !== ROUTER_SCROLL_TYPES.stay) {
        window.scrollTo(0, 0);
      }
    }, 10);
  }, [pathname, search, state, hash]);

  useEffect(() => {
    if (hash) {
      // Push onto callback queue so it runs after the DOM is updated,
      // this is required when navigating from a different page so that
      // the element is rendered on the page before trying to getElementById.
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element =
          document.getElementById(hash) || document.getElementById(id);

        if (element) {
          element.scrollIntoView({behavior: 'smooth', block: 'center'});
        }
      }, 200);
    }
  }, [hash]);

  return null;
}

function App() {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <div className="App">
        <Router history={browserHistory}>
          <ScrollToTop />

          <AppContextProvider>
            <Page>
              <GoogleTranslateElement />

              {renderRoutes(routes)}
            </Page>
          </AppContextProvider>
        </Router>
      </div>
    </ErrorBoundary>
  );
}

export default App;
