import http from 'utils/http';

export const addSearchAccountHistory = (data) => {
  return http('POST', `search/account-history`, data);
};

export const getSearchSuggestCompany = ({query, status = 'active'}) => {
  return http('GET', `search/suggest-company`, {
    q: query,
    filter: {company_status: status},
  });
};

export const getSearchSuggestCertificate = ({query, status = 'active'}) => {
  return http('GET', `search/suggest-certificate`, {
    q: query,
    filter: {status: status},
  });
};

export const getDashboardSearchLimit = (data) => {
  return http('GET', 'account/dashboard/search-limit', data);
};

// SEARCH_HISTORY
export const getAccountHistoryLastViewed = (data) => {
  return http('GET', 'account/history/last-viewed', data);
};

export const deleteAccountHistoryBulk = (data) => {
  return http('DELETE', 'account/history/bulk', data);
};

// MANUAL SEARCH
export const getSearchConfirmTransaction = (data) => {
  return http('GET', 'search/confirm-transaction', data);
};
