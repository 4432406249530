import {API_BM_URL} from 'configs/Environment';
import http, {makeSearchParams} from 'utils/http';

export const httpBM = (method = 'GET', url, data, options = {}) =>
  http(method, url, data, {...options, baseURL: API_BM_URL});

/**
 * @param data
 * @returns [*]
 */
export const getCountries = (data) => {
  return http('GET', 'g/countries', data);
};

/**
 * To get company detail using companyId.
 * Activation page is using this to get company detail.
 *
 * @param companyId
 * @returns {*}
 */
export const getCompanyById = (companyId) => {
  return http('GET', `g/company/${companyId}`);
};

/**
 *
 * @param params
 * @returns {*}
 */
export const getSchemes = (params) => {
  return http('GET', `g/schemes`, makeSearchParams(params));
};

// Unused
// export const getSchemesSectors = (params) => {
//   return http('GET', `cb-scheme-list`, makeSearchParams(params));
// };

/***
 * To get company detail using entityId.
 *
 * @param entityId
 * @returns {*}
 */
export const getRedirectById = (entityId) => {
  return http('GET', `rd/${entityId}`);
};

export const getAppById = (appId) => {
  return http('GET', `g/app/${appId}`);
};

export const getCSV = (type) => {
  return http('GET', `csv/${type}`);
};

export const syncQualityTrade = ({app_id, consent}) => {
  return http('POST', `qt/sync`, {
    app_id: app_id,
    consent: consent,
  });
};

export const getLandingStats = () => {
  return http('GET', `g/landing/stats`);
};

export const getLandingCountryStats = () => {
  return http('GET', `g/landing/country-stats`);
};

export const getCountriesCountsABCB = (queries) => {
  return http('GET', `g/countries/counts/ab-cb`, queries);
};

/**
 * Get CB list by CE ID
 * @param ceId
 * @param queries
 * @returns {*}
 */
export const getPublicCECBs = (ceId, queries) => {
  return http('GET', `g/ce/${ceId}/cb`, queries);
};

export const getPublicAccountOccupations = () => {
  return http('GET', '/g/account-occupations');
};

export const getPublicIndustries = () => {
  return http('GET', '/g/industries');
};
