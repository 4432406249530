import http from 'utils/http';

export const uploadFile = (data) => {
  return http('POST', 'upload/file', data);
};

export const uploadImage = (data, onUploadProgress) => {
  return http('POST', 'upload/image', data, {
    onUploadProgress: onUploadProgress,
  });
};
