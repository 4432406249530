import http from 'utils/http';
import {PROGRESS_GUIDE} from 'configs/Environment';

export const getGuidesByVersion = () => {
  return http('GET', `/guides/${PROGRESS_GUIDE}`);
};

export const putGuidesByVersion = (data) => {
  return http('PUT', `/guides/${PROGRESS_GUIDE}`, data);
};
