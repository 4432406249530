import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import {verifyAuthCaptcha} from 'api/auth';
import Cookies from 'utils/cookies';

import {
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
} from 'reactstrap';
import IPCaptcha from './IPCaptcha';
import CaptchaAlternativeLink from './CaptchaAlternativeLink';

const propTypes = {
  initialCheck: PropTypes.bool,
  onClose: PropTypes.func,
};

const defaultProps = {
  showChildren: true,
  initialCheck: false,
};

class CaptchaModal extends React.Component {
  constructor(props) {
    super(props);

    const {initialCheck} = props;
    const countryCode = Cookies.countryCode || '';
    const captchaToken = Cookies.captchaToken || '';
    const isVerified = !!captchaToken;

    this.state = {
      isLoading: false,
      countryCode,
      isVerified,
      captchaToken,
      isOpen: initialCheck && !isVerified,
    };

    this.checkIPAddress = this.checkIPAddress.bind(this);
    this.verifyCaptcha = this.verifyCaptcha.bind(this);
    this.handleVerifyCaptcha = this.handleVerifyCaptcha.bind(this);
    // this.handleVerifyHCaptcha = this.handleVerifyHCaptcha.bind(this);
    // this.handleVerifyGoogleCaptcha = this.handleVerifyGoogleCaptcha.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    const {initialCheck} = this.props;
    const {countryCode} = this.state;

    if (!countryCode) {
      this.checkIPAddress();
    }

    if (initialCheck) {
      this.verifyCaptcha();
    }
  }

  verifyCaptcha() {
    const captchaToken = Cookies.captchaToken || '';
    const isVerified = !!captchaToken;

    this.setState({
      captchaToken: captchaToken,
      isVerified: isVerified,
      isOpen: !isVerified,
    });
  }

  handleClose(e) {
    e.preventDefault();
    const {onClose} = this.props;
    if (onClose) {
      onClose();
    } else {
      this.setState({isOpen: false});
    }
  }

  async checkIPAddress() {
    try {
      const instance = axios.create();
      instance.interceptors.request.use(function (config) {
        //delete axios.defaults.headers.common['x-http-bsid'];
        delete config.headers.common['x-http-bsid']; // Remove
        return config;
      });

      //delete instance.headers.common['x-http-bsid'];
      const {data} = await instance.get('https://ipapi.co/json');
      const {country_code} = data || {};
      Cookies.countryCode = country_code;

      this.setState({countryCode: country_code});
    } catch (e) {
      throw e;
    }
  }

  async handleVerifyCaptcha(code, type) {
    try {
      this.setState({isLoading: true});
      const {data} = await verifyAuthCaptcha(type, {response: code});
      const {token_id} = data;
      Cookies.captchaToken = token_id;

      this.setState({isVerified: true, isOpen: false});
    } catch (e) {
      throw e;
    }
  }

  // async handleVerifyGoogleCaptcha(code) {
  //   try {
  //     this.setState({isLoading: true});
  //     const {data} = await verifyAuthCaptcha('recaptcha', {
  //       //'g-recaptcha-response': code,
  //       response: code,
  //     });
  //     const {token_id} = data;
  //     Cookies.captchaToken = token_id;

  //     this.setState({isVerified: true, isOpen: false});
  //   } catch (e) {
  //     throw e;
  //   }
  // }

  // async handleVerifyHCaptcha(code) {
  //   try {
  //     this.setState({isLoading: true});
  //     const {data} = await verifyAuthCaptcha('hcaptcha', {response: code});
  //     const {token_id} = data;
  //     Cookies.captchaToken = token_id;

  //     this.setState({isVerified: true, isOpen: false});
  //   } catch (e) {
  //     throw e;
  //   }
  // }

  render() {
    const {showChildren, children} = this.props;
    const {isLoading, isOpen, isVerified} = this.state;

    return (
      <React.Fragment>
        {(isVerified || showChildren) &&
          children({
            isVerified: isVerified,
            verifyCaptcha: this.verifyCaptcha,
          })}

        <Modal centered isOpen={isOpen}>
          <ModalHeader>Security Verification</ModalHeader>
          <ModalBody>
            <p>
              Before you use IAF CertSearch, we need to confirm you are a human.
            </p>
            <div style={{maxWidth: '320px', margin: '0 auto'}}>
              <FormGroup className="my-4 d-flex justify-content-center flex-column">
                <IPCaptcha onVerify={this.handleVerifyCaptcha} />

                <div className="mt-2 text-center">
                  <CaptchaAlternativeLink />
                </div>
              </FormGroup>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button onClick={this.handleClose} disabled={isLoading}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

CaptchaModal.propTypes = propTypes;
CaptchaModal.defaultProps = defaultProps;

export default CaptchaModal;
