import http, {makeSearchParams} from 'utils/http';
import {httpBM} from './common';

// Field Mapping
export const getBmGlobalFieldMapping = () => {
  return httpBM('GET', '/frontend/bm-global-field-mapping');
};

export const postBmGlobalFieldMapping = (formData) => {
  return httpBM('POST', `/frontend/bm-global-field-mapping`, formData);
};

export const putBmGlobalFieldMapping = (id, formData) => {
  return httpBM('PUT', `/frontend/bm-global-field-mapping/${id}`, formData);
};

export const deleteBmGlobalFieldMapping = (ids) => {
  return httpBM('DELETE', `/frontend/bm-global-field-mapping`, ids);
};

export const getBmGlobalFieldMappingTarget = (queries) => {
  return httpBM(
    'GET',
    '/frontend/bm-global-field-mapping/target',
    makeSearchParams(queries)
  );
};

// File Upload
export const postCompanyListImport = (data) => {
  return httpBM('POST', '/frontend/company-list/import', data);
};

// Bulk matching company lists
export const postCompanylistMatch = (clId = 0, payload) => {
  return httpBM('POST', `/frontend/company-list/${clId}/match`, payload);
};

export const getActivityProgress = (clId = 0) => {
  return httpBM('GET', `/frontend/check-activities/progress/${clId}`);
};

export const getActivityProcess = (clId = 0) => {
  return httpBM('GET', `/frontend/check-activities/process/${clId}`);
};

// Bulk matching activities
export const getActivities = (queries) => {
  return httpBM(
    'GET',
    '/frontend/file-upload-activities',
    makeSearchParams(queries)
  );
};

// Bulk matching activity detail
export const getFileUploadActivityById = (id) => {
  return httpBM('GET', `/frontend/file-upload-activities/activity/${id}`);
};

export const getActivityFile = (uploaded_at) => {
  return httpBM(
    'GET',
    `/frontend/file-upload-activities/download/${uploaded_at}`,
    {},
    {responseType: 'arraybuffer'}
  );
};

export const getActivityById = (activityId) => {
  return httpBM('GET', `/frontend/file-upload-activities/${activityId}`);
};

// verifying certifications
export const postVerifyCeCerts = (clId = 0, payload) => {
  return httpBM(
    'POST',
    `/frontend/company-list/${clId}/verify-certifications/ce`,
    payload
  );
};

// verify certifications
export const postVerifyCerts = (clId = 0, payload) => {
  return httpBM(
    'POST',
    `/frontend/company-list/${clId}/verify-certifications/cert`,
    payload
  );
};

// Import Settings
export const getImportSettings = () => {
  return http('GET', '/import/settings');
};

export const putImportSettings = (payload) => {
  return http('PUT', '/import/settings', payload);
};
